<template>
    <main class="main">
        <section class="container">
            <Crumbs :data="crumbs"></Crumbs>

            <div class="documents__page-wrapper">
                <Documents :data="documents"></Documents>
            </div>
        </section>
        <section class="container">
            <div class="documents__page-wrapper">
                <div class="transport_text-wrapper">
					<span>По вопросам техподдержки:</span>
				</div>
                <div class="transport_text-wrapper">
					<span>тел: <a href="tel:83433857520">+7 (343) 385-75-20</a></span>
					<span>e-mail: <a href="mailto:transport@eskso.ru">transport@eskso.ru</a></span>
				</div>
            </div>
        </section>
    </main>
</template>

<style>
.transport_text-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 24px;
}
.transport_text-wrapper span {
	display: inline-block;
	margin: 0 18px; 
}
</style>

<script>
import Crumbs from '@/components/Crumbs.vue'
import Documents from '@/components/Documents.vue'

export default {
	name: 'Transport',

	components: {
		Crumbs,
		Documents,
	},

	data() {
		return {
			crumbs: null,
		}
	},

	mounted() {
		this.crumbs = [
			{
				link: 'PartnerMaterials',
				text: 'Материалы',
			},
			{
				link: null,
				text: 'Руководства и регламенты по предоставлению МСП на транспорте по ЕСК',
			},
		]
	},

	computed: {
		documents() {
			return {
				title: 'Руководства и регламенты по предоставлению МСП на транспорте по ЕСК',
				type: 'transport',
			}
		},
	},
}
</script>